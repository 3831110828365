import type {
  EventDate,
  EventDateType,
  EventDescription,
} from '~/composables/types/event'

export const useEventData = () =>
  useState<EventDescription | undefined>('eventData', () => undefined)

// handle correct redirects for events and box pages
export const useEventRedirects = async (
  eventData: Ref<EventDescription>,
  eventDataLoadError: boolean,
  isGiftcard: boolean,
  eventPermalink: string
) => {
  const localePath = useLocalePath()
  const route = useRoute()
  switch (true) {
    // if event is not found, throw 404
    case !eventData || eventDataLoadError:
      throw showError({
        statusCode: 404,
        message: 'Page Not Found',
      })
    // if page is in route /e/[permalink] but it's actually a giftcard page, throw 404
    case isGiftcard !== eventGetters.isGiftcardEvent(eventData):
      throw showError({
        statusCode: 404,
        message: 'Page Not Found',
      })
    // when it should be a box page (/b), but we land to the event page (/e)
    case eventGetters.getAttendanceMode(eventData)?.toUpperCase() ===
      'NATIONWIDE' && route.path.includes('/e/'):
      await navigateTo(localePath(`/k/${eventPermalink}`))
      break
    // when it should be an event page (/e), but we land to the box page (/b)
    case eventGetters.getAttendanceMode(eventData)?.toUpperCase() !==
      'NATIONWIDE' && route.path.includes('/k/'):
      await navigateTo(localePath(`/e/${eventPermalink}`))
      break
  }
}

export const useEventDatesStore = (
  id: Ref<string> | string,
  type: EventDateType
) => {
  const dates = useState<EventDate[]>(
    `eventDateData-${toValue(id)}-${type}`,
    () => []
  )

  const set = (newDates: EventDate[]) => {
    dates.value = newDates
  }

  return {
    dates,
    set,
  }
}
